<template>
  <div class="my-register">
    <fb-header slot="header" title="邀请注册" fixed>
      <fb-button slot="left" icon="back" size="small" @click="goBack" type="primary">
      </fb-button>
    </fb-header>
    <div class="register-content">
      <shareInfo mode="reg" />
    </div>
  </div>
</template>

<script>
import shareInfo from '../myShare/components/shareInfo'
export default {
  name: 'my-register',
  components: {
    shareInfo,
  },
  data() {
    return {}
  },
  methods: {
    goBack() {
      if (this.$router.length <= 1) {
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    backToNative() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const flutterUA = 'fb-flutter-Webview'
      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          console.log('AndroidUA**********')
          // eslint-disable-next-line
          AND2JS.back()
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === iOSUA) {
        try {
          console.log('iOSUA**********')
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === flutterUA) {
        try {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } catch (err) {
          this.$notify('没有可以返回的页面')
          console.log('back.postMessage', err)
        }
      } else {
        console.log('router**********')
        this.$router.back()
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.my-register
  .fb-header
    background #409eff
    color #fff
    .fb-header-button
      .fb-button
        color #fff
.register-content
  width 100%
  padding-top 50px
</style>
