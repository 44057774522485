var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-register" },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "邀请注册", fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: { click: _vm.goBack },
            slot: "left",
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "register-content" },
        [_c("shareInfo", { attrs: { mode: "reg" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }